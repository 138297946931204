import { useParams } from "react-router-dom";
import { useEffect } from "react";
import TextField from "@mui/material/TextField";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment-timezone";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import TimezoneSelection from "./TimezoneSelection";
import DeleteIcon from "@mui/icons-material/Delete";
import AutoDeleteIcon from "@mui/icons-material/AutoDelete";
import { usePersistent } from "./ProviderPersistent";
import { useTransient } from "./ProviderTransient";
import { Box } from "@mui/system";
import Link from "@mui/material/Link";

const Entry = () => {
  const [{ timers }, dispatchPersistent] = usePersistent();
  const [{ entry }, dispatchTransient] = useTransient();

  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    if (params.id) {
      const timer = timers.find((timer) => timer.id === params.id);
      if (timer) {
        dispatchTransient(["initEntry", { timer }]);
      } else {
        navigate("/");
      }
    } else {
      dispatchTransient(["initEntry"]);
    }
  }, [params, navigate]);

  const showDialog = () => {
    dispatchTransient(["updateEntry", { showDialog: true }]);
  };
  const handleTitleChange = (event) => {
    const title = event.target.value;
    let titleError = "";
    if (!title) {
      titleError = "Title is required";
    }
    dispatchTransient(["updateEntry", { title, titleError }]);
  };
  const handleDateChange = (date) => {
    let dateError = "";
    if (!date) {
      dateError = "Date is required";
    } else if (!date.isValid()) {
      dateError = "Date is invalid";
    }
    dispatchTransient(["updateEntry", { date, dateError }]);
  };
  const handleTimeChange = (time) => {
    let timeError = "";
    if (!time) {
      timeError = "Time is required";
    } else if (!time.isValid()) {
      timeError = "Time is invalid";
    }
    dispatchTransient(["updateEntry", { time, timeError }]);
  };

  const handleSave = (event) => {
    event.preventDefault();
    if (entry.titleError || entry.dateError || entry.timeError) {
      dispatchTransient(["updateEntry", { showErrors: true }]);
    } else {
      const timer = {
        title: entry.title,
        dateTime: moment
          .tz(
            entry.date.format("YYYY-MM-DD") + "T" + entry.time.format("HH:mm"),
            entry.timezone
          )
          .utc()
          .format(),
        timezone: entry.timezone,
      };
      if (params.id) {
        timer.id = params.id;
        dispatchPersistent(["updateTimer", { timer }]);
      } else {
        dispatchPersistent(["addTimer", { timer }]);
      }
      navigate("/");
    }
  };
  const handleCancel = () => navigate("/");
  const handleDeleteDown = () => {
    const deleteTimeout = setTimeout(() => {
      dispatchPersistent(["deleteTimer", { timerId: params.id }]);
      navigate("/");
    }, 1000);
    dispatchTransient(["updateEntry", { deleteTimeout }]);
  };
  const handleDeleteUp = () => {
    if (entry.deleteTimeout) {
      clearTimeout(entry.deleteTimeout);
      dispatchTransient(["updateEntry", { deleteTimeout: null }]);
    }
  };

  return (
    <form>
      <Stack spacing={2} sx={{ maxWidth: "300px", mx: "auto" }}>
        <Stack direction="row" spacing={2} sx={{ alignItems: "center" }}>
          <Typography variant="h5" sx={{ flexGrow: 1 }}>
            {params.id ? "Edit" : "New"} Timer
          </Typography>
          {params.id && (
            <Tooltip
              title="Hold to Delete"
              placement="bottom-end"
              enterTouchDelay={0}
            >
              <IconButton
                onPointerDown={handleDeleteDown}
                onPointerUp={handleDeleteUp}
                onPointerOut={handleDeleteUp}
                color="inherit"
              >
                {entry.deleteTimeout ? <AutoDeleteIcon /> : <DeleteIcon />}
              </IconButton>
            </Tooltip>
          )}
        </Stack>
        <TextField
          label="Title"
          value={entry.title}
          error={entry.showErrors && entry.titleError !== ""}
          helperText={entry.showErrors && entry.titleError}
          autoFocus
          onChange={handleTitleChange}
        />
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            openTo="year"
            views={["year", "month", "day"]}
            label="Date"
            onChange={handleDateChange}
            value={entry.date}
            renderInput={(params) => (
              <TextField
                {...params}
                error={entry.showErrors && entry.dateError !== ""}
                helperText={entry.showErrors && entry.dateError}
              />
            )}
          />
        </LocalizationProvider>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <TimePicker
            label="Time"
            onChange={handleTimeChange}
            value={entry.time}
            renderInput={(params) => (
              <TextField
                {...params}
                error={entry.showErrors && entry.timeError !== ""}
                helperText={entry.showErrors && entry.timeError}
              />
            )}
          />
        </LocalizationProvider>
        <Box>
          Timezone:{" "}
          <Link
            component="button"
            type="button"
            variant="body1"
            onClick={() => showDialog()}
          >
            {entry.timezone}
          </Link>
        </Box>
        {entry.showDialog && <TimezoneSelection />}
        <Stack direction="row" spacing={2} sx={{ justifyContent: "flex-end" }}>
          <Button variant="text" onClick={handleCancel}>
            Cancel
          </Button>
          <Button variant="contained" onClick={handleSave} type="submit">
            Save
          </Button>
        </Stack>
      </Stack>
    </form>
  );
};

export default Entry;
