import React from "react";
import Grid from "@mui/material/Grid";
import Timer from "./Timer";
import Milestones from "./Milestones";
import { useTransient } from "./ProviderTransient";

const Timers = ({ timers }) => {
  const [{ milestoneDate }] = useTransient();

  return (
    <>
      <Grid container justifyContent="center" spacing={3}>
        {timers.map((timer) => (
          <Grid item key={timer.id || timer.title}>
            <Timer timer={timer} />
          </Grid>
        ))}
      </Grid>
      {milestoneDate && <Milestones />}
    </>
  );
};

export default Timers;
