import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import Entry from "./Entry";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Home";
import Help from "./Help";
import Custom from "./Custom";
import "./index.css";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />}>
          <Route path="" element={<Home />} />
          <Route path="entry" element={<Entry />} />
          <Route path="entry/:id" element={<Entry />} />
          <Route path="custom/:dateTime/:title" element={<Custom />} />
          <Route path="help" element={<Help />} />
        </Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);
