import React from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment-timezone";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Timers from "./Timers";

const Help = () => {
  const navigate = useNavigate();

  const year = moment().year();
  const timers = [
    {
      dateTime: moment(year + "-01-01T00:00:00")
        .utc()
        .format(),
      title: "Past Date",
    },
    {
      dateTime: moment(year + 1 + "-01-01T00:00:00")
        .utc()
        .format(),
      title: "Future Date",
    },
  ];
  const handleNewClick = (event) => {
    event.preventDefault();
    navigate("/entry");
  };

  return (
    <>
      <Stack spacing={2} pb={3}>
        <Typography variant="h5">Wecome to How Many Seconds</Typography>
        <Typography>
          Create timers like the ones below by selecting "New Timer" in the menu
          (or by clicking{" "}
          <Link href="/entry" onClick={handleNewClick}>
            here
          </Link>
          ). You can enter a past date to see how long it's been since an event
          occurred. For example, entering your birthday will show you how many
          seconds, minutes, hours, days, weeks, months and years old you are.
          You can also enter a future date to track how much time is left until
          the event. For example, how long until vacation starts.
        </Typography>
        <Typography>
          Clicking "Milestone" within a timer will show you the dates of future
          milestones. You can also see the crossed out dates of milestones that
          have already occurred.
        </Typography>
      </Stack>
      <Timers timers={timers} />
      <Stack spacing={2} pt={3}>
        <Typography>A few things to keep in mind.</Typography>
        <ul>
          <li>
            Dates and times shown for timers and milestones are adjusted to your
            current timezone ({moment.tz.guess()}). This means that the
            date/time you entered may differ from what is displayed if you are
            currently in a different timezone then where the event occurred or
            will occur.
          </li>
          <li>
            Milestones for days, weeks, months and years may occationally have
            different times associated with them. This is because of daylight
            saving time. For example, exactly one month from Mar 5, 2019 3:30 PM
            could be Apr 5, 2019 4:30 PM.
          </li>
          <li>
            Timers are stored locally in your browser using localStorage. Timers
            will need to be entered again if you clear your browser's
            localStorage.
          </li>
          <li>
            <b>
              Timers are for entertainment purposes only, do not rely on
              displayed information.
            </b>
          </li>
        </ul>
        <Typography>Why are there so many timezones to choose from?</Typography>
        <ul>
          <li>
            Unfortuneatley, choosing something like Central Time is not enough.
            Central Time switches between Central Standard Time (GMT-6) and
            Central Daylight Time (GMT-5). Historically, regions within Central
            Time have switched between these two on different dates.
          </li>
          <li>
            Even if daylight saving time was abandoned going forward, Central
            time would not be enough since How Many Seconds uses past dates as
            well.
          </li>
        </ul>
        <Typography>
          I'd like to hear from you, please let me know if you have any
          questions or feedback. Email me at{" "}
          <Link href="mailto:xtasystems@gmail.com">xtasystems@gmail.com</Link>{" "}
          and let me know your thoughts!
        </Typography>
      </Stack>
    </>
  );
};

export default Help;
