const timezones = [
  { country: "Afghanistan", label: "Asia/Kabul" },
  { country: "Aland Islands", label: "Europe/Helsinki" },
  { country: "Aland Islands", label: "Europe/Mariehamn" },
  { country: "Albania", label: "Europe/Tirane" },
  { country: "Algeria", label: "Africa/Algiers" },
  { country: "American Samoa", label: "Pacific/Pago_Pago" },
  { country: "Andorra", label: "Europe/Andorra" },
  { country: "Angola", label: "Africa/Lagos" },
  { country: "Angola", label: "Africa/Luanda" },
  { country: "Anguilla", label: "America/Anguilla" },
  { country: "Anguilla", label: "America/Port_of_Spain" },
  { country: "Antarctica", label: "Antarctica/Casey" },
  { country: "Antarctica", label: "Antarctica/Davis" },
  { country: "Antarctica", label: "Antarctica/DumontDUrville" },
  { country: "Antarctica", label: "Antarctica/Mawson" },
  { country: "Antarctica", label: "Antarctica/McMurdo" },
  { country: "Antarctica", label: "Antarctica/Palmer" },
  { country: "Antarctica", label: "Antarctica/Rothera" },
  { country: "Antarctica", label: "Antarctica/Syowa" },
  { country: "Antarctica", label: "Antarctica/Troll" },
  { country: "Antarctica", label: "Antarctica/Vostok" },
  { country: "Antarctica", label: "Pacific/Auckland" },
  { country: "Antigua And Barbuda", label: "America/Antigua" },
  { country: "Antigua And Barbuda", label: "America/Port_of_Spain" },
  { country: "Argentina", label: "America/Argentina/Buenos_Aires" },
  { country: "Argentina", label: "America/Argentina/Catamarca" },
  { country: "Argentina", label: "America/Argentina/Cordoba" },
  { country: "Argentina", label: "America/Argentina/Jujuy" },
  { country: "Argentina", label: "America/Argentina/La_Rioja" },
  { country: "Argentina", label: "America/Argentina/Mendoza" },
  { country: "Argentina", label: "America/Argentina/Rio_Gallegos" },
  { country: "Argentina", label: "America/Argentina/Salta" },
  { country: "Argentina", label: "America/Argentina/San_Juan" },
  { country: "Argentina", label: "America/Argentina/San_Luis" },
  { country: "Argentina", label: "America/Argentina/Tucuman" },
  { country: "Argentina", label: "America/Argentina/Ushuaia" },
  { country: "Armenia", label: "Asia/Yerevan" },
  { country: "Aruba", label: "America/Aruba" },
  { country: "Aruba", label: "America/Curacao" },
  { country: "Australia", label: "Antarctica/Macquarie" },
  { country: "Australia", label: "Australia/Adelaide" },
  { country: "Australia", label: "Australia/Brisbane" },
  { country: "Australia", label: "Australia/Broken_Hill" },
  { country: "Australia", label: "Australia/Darwin" },
  { country: "Australia", label: "Australia/Eucla" },
  { country: "Australia", label: "Australia/Hobart" },
  { country: "Australia", label: "Australia/Lindeman" },
  { country: "Australia", label: "Australia/Lord_Howe" },
  { country: "Australia", label: "Australia/Melbourne" },
  { country: "Australia", label: "Australia/Perth" },
  { country: "Australia", label: "Australia/Sydney" },
  { country: "Austria", label: "Europe/Vienna" },
  { country: "Azerbaijan", label: "Asia/Baku" },
  { country: "Bahamas", label: "America/Nassau" },
  { country: "Bahrain", label: "Asia/Bahrain" },
  { country: "Bahrain", label: "Asia/Qatar" },
  { country: "Bangladesh", label: "Asia/Dhaka" },
  { country: "Barbados", label: "America/Barbados" },
  { country: "Belarus", label: "Europe/Minsk" },
  { country: "Belgium", label: "Europe/Brussels" },
  { country: "Belize", label: "America/Belize" },
  { country: "Benin", label: "Africa/Lagos" },
  { country: "Benin", label: "Africa/Porto-Novo" },
  { country: "Bermuda", label: "Atlantic/Bermuda" },
  { country: "Bhutan", label: "Asia/Thimphu" },
  { country: "Bolivia", label: "America/La_Paz" },
  { country: "Bonaire, Sint Eustatius And Saba", label: "America/Curacao" },
  { country: "Bonaire, Sint Eustatius And Saba", label: "America/Kralendijk" },
  { country: "Bosnia And Herzegovina", label: "Europe/Belgrade" },
  { country: "Bosnia And Herzegovina", label: "Europe/Sarajevo" },
  { country: "Botswana", label: "Africa/Gaborone" },
  { country: "Botswana", label: "Africa/Maputo" },
  { country: "Brazil", label: "America/Araguaina" },
  { country: "Brazil", label: "America/Bahia" },
  { country: "Brazil", label: "America/Belem" },
  { country: "Brazil", label: "America/Boa_Vista" },
  { country: "Brazil", label: "America/Campo_Grande" },
  { country: "Brazil", label: "America/Cuiaba" },
  { country: "Brazil", label: "America/Eirunepe" },
  { country: "Brazil", label: "America/Fortaleza" },
  { country: "Brazil", label: "America/Maceio" },
  { country: "Brazil", label: "America/Manaus" },
  { country: "Brazil", label: "America/Noronha" },
  { country: "Brazil", label: "America/Porto_Velho" },
  { country: "Brazil", label: "America/Recife" },
  { country: "Brazil", label: "America/Rio_Branco" },
  { country: "Brazil", label: "America/Santarem" },
  { country: "Brazil", label: "America/Sao_Paulo" },
  { country: "British Indian Ocean Territory", label: "Indian/Chagos" },
  { country: "Brunei Darussalam", label: "Asia/Brunei" },
  { country: "Bulgaria", label: "Europe/Sofia" },
  { country: "Burkina Faso", label: "Africa/Abidjan" },
  { country: "Burkina Faso", label: "Africa/Ouagadougou" },
  { country: "Burundi", label: "Africa/Bujumbura" },
  { country: "Burundi", label: "Africa/Maputo" },
  { country: "Cambodia", label: "Asia/Bangkok" },
  { country: "Cambodia", label: "Asia/Phnom_Penh" },
  { country: "Cameroon", label: "Africa/Douala" },
  { country: "Cameroon", label: "Africa/Lagos" },
  { country: "Canada", label: "America/Atikokan" },
  { country: "Canada", label: "America/Blanc-Sablon" },
  { country: "Canada", label: "America/Cambridge_Bay" },
  { country: "Canada", label: "America/Creston" },
  { country: "Canada", label: "America/Dawson" },
  { country: "Canada", label: "America/Dawson_Creek" },
  { country: "Canada", label: "America/Edmonton" },
  { country: "Canada", label: "America/Fort_Nelson" },
  { country: "Canada", label: "America/Glace_Bay" },
  { country: "Canada", label: "America/Goose_Bay" },
  { country: "Canada", label: "America/Halifax" },
  { country: "Canada", label: "America/Inuvik" },
  { country: "Canada", label: "America/Iqaluit" },
  { country: "Canada", label: "America/Moncton" },
  { country: "Canada", label: "America/Nipigon" },
  { country: "Canada", label: "America/Pangnirtung" },
  { country: "Canada", label: "America/Rainy_River" },
  { country: "Canada", label: "America/Rankin_Inlet" },
  { country: "Canada", label: "America/Regina" },
  { country: "Canada", label: "America/Resolute" },
  { country: "Canada", label: "America/St_Johns" },
  { country: "Canada", label: "America/Swift_Current" },
  { country: "Canada", label: "America/Thunder_Bay" },
  { country: "Canada", label: "America/Toronto" },
  { country: "Canada", label: "America/Vancouver" },
  { country: "Canada", label: "America/Whitehorse" },
  { country: "Canada", label: "America/Winnipeg" },
  { country: "Canada", label: "America/Yellowknife" },
  { country: "Cape Verde", label: "Atlantic/Cape_Verde" },
  { country: "Cayman Islands", label: "America/Cayman" },
  { country: "Cayman Islands", label: "America/Panama" },
  { country: "Central African Republic", label: "Africa/Bangui" },
  { country: "Central African Republic", label: "Africa/Lagos" },
  { country: "Chad", label: "Africa/Ndjamena" },
  { country: "Chile", label: "America/Punta_Arenas" },
  { country: "Chile", label: "America/Santiago" },
  { country: "Chile", label: "Pacific/Easter" },
  { country: "China", label: "Asia/Shanghai" },
  { country: "China", label: "Asia/Urumqi" },
  { country: "Christmas Island", label: "Indian/Christmas" },
  { country: "Cocos (Keeling) Islands", label: "Indian/Cocos" },
  { country: "Colombia", label: "America/Bogota" },
  { country: "Comoros", label: "Africa/Nairobi" },
  { country: "Comoros", label: "Indian/Comoro" },
  { country: "Congo", label: "Africa/Brazzaville" },
  { country: "Congo", label: "Africa/Lagos" },
  { country: "Congo, Democratic Republic", label: "Africa/Kinshasa" },
  { country: "Congo, Democratic Republic", label: "Africa/Lagos" },
  { country: "Congo, Democratic Republic", label: "Africa/Lubumbashi" },
  { country: "Congo, Democratic Republic", label: "Africa/Maputo" },
  { country: "Cook Islands", label: "Pacific/Rarotonga" },
  { country: "Costa Rica", label: "America/Costa_Rica" },
  { country: "Cote D'Ivoire", label: "Africa/Abidjan" },
  { country: "Croatia", label: "Europe/Belgrade" },
  { country: "Croatia", label: "Europe/Zagreb" },
  { country: "Cuba", label: "America/Havana" },
  { country: "Curacao", label: "America/Curacao" },
  { country: "Cyprus", label: "Asia/Famagusta" },
  { country: "Cyprus", label: "Asia/Nicosia" },
  { country: "Czech Republic", label: "Europe/Prague" },
  { country: "Denmark", label: "Europe/Copenhagen" },
  { country: "Djibouti", label: "Africa/Djibouti" },
  { country: "Djibouti", label: "Africa/Nairobi" },
  { country: "Dominica", label: "America/Dominica" },
  { country: "Dominica", label: "America/Port_of_Spain" },
  { country: "Dominican Republic", label: "America/Santo_Domingo" },
  { country: "Ecuador", label: "America/Guayaquil" },
  { country: "Ecuador", label: "Pacific/Galapagos" },
  { country: "Egypt", label: "Africa/Cairo" },
  { country: "El Salvador", label: "America/El_Salvador" },
  { country: "Equatorial Guinea", label: "Africa/Lagos" },
  { country: "Equatorial Guinea", label: "Africa/Malabo" },
  { country: "Eritrea", label: "Africa/Asmara" },
  { country: "Eritrea", label: "Africa/Nairobi" },
  { country: "Estonia", label: "Europe/Tallinn" },
  { country: "Ethiopia", label: "Africa/Addis_Ababa" },
  { country: "Ethiopia", label: "Africa/Nairobi" },
  { country: "Falkland Islands (Malvinas)", label: "Atlantic/Stanley" },
  { country: "Faroe Islands", label: "Atlantic/Faroe" },
  { country: "Fiji", label: "Pacific/Fiji" },
  { country: "Finland", label: "Europe/Helsinki" },
  { country: "France", label: "Europe/Paris" },
  { country: "French Guiana", label: "America/Cayenne" },
  { country: "French Polynesia", label: "Pacific/Gambier" },
  { country: "French Polynesia", label: "Pacific/Marquesas" },
  { country: "French Polynesia", label: "Pacific/Tahiti" },
  { country: "French Southern Territories", label: "Indian/Kerguelen" },
  { country: "French Southern Territories", label: "Indian/Reunion" },
  { country: "Gabon", label: "Africa/Lagos" },
  { country: "Gabon", label: "Africa/Libreville" },
  { country: "Gambia", label: "Africa/Abidjan" },
  { country: "Gambia", label: "Africa/Banjul" },
  { country: "Georgia", label: "Asia/Tbilisi" },
  { country: "Germany", label: "Europe/Berlin" },
  { country: "Germany", label: "Europe/Busingen" },
  { country: "Germany", label: "Europe/Zurich" },
  { country: "Ghana", label: "Africa/Accra" },
  { country: "Gibraltar", label: "Europe/Gibraltar" },
  { country: "Greece", label: "Europe/Athens" },
  { country: "Greenland", label: "America/Danmarkshavn" },
  { country: "Greenland", label: "America/Nuuk" },
  { country: "Greenland", label: "America/Scoresbysund" },
  { country: "Greenland", label: "America/Thule" },
  { country: "Grenada", label: "America/Grenada" },
  { country: "Grenada", label: "America/Port_of_Spain" },
  { country: "Guadeloupe", label: "America/Guadeloupe" },
  { country: "Guadeloupe", label: "America/Port_of_Spain" },
  { country: "Guam", label: "Pacific/Guam" },
  { country: "Guatemala", label: "America/Guatemala" },
  { country: "Guernsey", label: "Europe/Guernsey" },
  { country: "Guernsey", label: "Europe/London" },
  { country: "Guinea", label: "Africa/Abidjan" },
  { country: "Guinea", label: "Africa/Conakry" },
  { country: "Guinea-Bissau", label: "Africa/Bissau" },
  { country: "Guyana", label: "America/Guyana" },
  { country: "Haiti", label: "America/Port-au-Prince" },
  { country: "Holy See (Vatican City State)", label: "Europe/Rome" },
  { country: "Holy See (Vatican City State)", label: "Europe/Vatican" },
  { country: "Honduras", label: "America/Tegucigalpa" },
  { country: "Hong Kong", label: "Asia/Hong_Kong" },
  { country: "Hungary", label: "Europe/Budapest" },
  { country: "Iceland", label: "Atlantic/Reykjavik" },
  { country: "India", label: "Asia/Kolkata" },
  { country: "Indonesia", label: "Asia/Jakarta" },
  { country: "Indonesia", label: "Asia/Jayapura" },
  { country: "Indonesia", label: "Asia/Makassar" },
  { country: "Indonesia", label: "Asia/Pontianak" },
  { country: "Iran, Islamic Republic Of", label: "Asia/Tehran" },
  { country: "Iraq", label: "Asia/Baghdad" },
  { country: "Ireland", label: "Europe/Dublin" },
  { country: "Isle Of Man", label: "Europe/Isle_of_Man" },
  { country: "Isle Of Man", label: "Europe/London" },
  { country: "Israel", label: "Asia/Jerusalem" },
  { country: "Italy", label: "Europe/Rome" },
  { country: "Jamaica", label: "America/Jamaica" },
  { country: "Japan", label: "Asia/Tokyo" },
  { country: "Jersey", label: "Europe/Jersey" },
  { country: "Jersey", label: "Europe/London" },
  { country: "Jordan", label: "Asia/Amman" },
  { country: "Kazakhstan", label: "Asia/Almaty" },
  { country: "Kazakhstan", label: "Asia/Aqtau" },
  { country: "Kazakhstan", label: "Asia/Aqtobe" },
  { country: "Kazakhstan", label: "Asia/Atyrau" },
  { country: "Kazakhstan", label: "Asia/Oral" },
  { country: "Kazakhstan", label: "Asia/Qostanay" },
  { country: "Kazakhstan", label: "Asia/Qyzylorda" },
  { country: "Kenya", label: "Africa/Nairobi" },
  { country: "Kiribati", label: "Pacific/Enderbury" },
  { country: "Kiribati", label: "Pacific/Kiritimati" },
  { country: "Kiribati", label: "Pacific/Tarawa" },
  { country: "Korea", label: "Asia/Seoul" },
  { country: "Kuwait", label: "Asia/Kuwait" },
  { country: "Kuwait", label: "Asia/Riyadh" },
  { country: "Kyrgyzstan", label: "Asia/Bishkek" },
  { country: "Lao People's Democratic Republic", label: "Asia/Bangkok" },
  { country: "Lao People's Democratic Republic", label: "Asia/Vientiane" },
  { country: "Latvia", label: "Europe/Riga" },
  { country: "Lebanon", label: "Asia/Beirut" },
  { country: "Lesotho", label: "Africa/Johannesburg" },
  { country: "Lesotho", label: "Africa/Maseru" },
  { country: "Liberia", label: "Africa/Monrovia" },
  { country: "Libyan Arab Jamahiriya", label: "Africa/Tripoli" },
  { country: "Liechtenstein", label: "Europe/Vaduz" },
  { country: "Liechtenstein", label: "Europe/Zurich" },
  { country: "Lithuania", label: "Europe/Vilnius" },
  { country: "Luxembourg", label: "Europe/Luxembourg" },
  { country: "Macao", label: "Asia/Macau" },
  { country: "Macedonia", label: "Europe/Belgrade" },
  { country: "Macedonia", label: "Europe/Skopje" },
  { country: "Madagascar", label: "Africa/Nairobi" },
  { country: "Madagascar", label: "Indian/Antananarivo" },
  { country: "Malawi", label: "Africa/Blantyre" },
  { country: "Malawi", label: "Africa/Maputo" },
  { country: "Malaysia", label: "Asia/Kuala_Lumpur" },
  { country: "Malaysia", label: "Asia/Kuching" },
  { country: "Maldives", label: "Indian/Maldives" },
  { country: "Mali", label: "Africa/Abidjan" },
  { country: "Mali", label: "Africa/Bamako" },
  { country: "Malta", label: "Europe/Malta" },
  { country: "Marshall Islands", label: "Pacific/Kwajalein" },
  { country: "Marshall Islands", label: "Pacific/Majuro" },
  { country: "Martinique", label: "America/Martinique" },
  { country: "Mauritania", label: "Africa/Abidjan" },
  { country: "Mauritania", label: "Africa/Nouakchott" },
  { country: "Mauritius", label: "Indian/Mauritius" },
  { country: "Mayotte", label: "Africa/Nairobi" },
  { country: "Mayotte", label: "Indian/Mayotte" },
  { country: "Mexico", label: "America/Bahia_Banderas" },
  { country: "Mexico", label: "America/Cancun" },
  { country: "Mexico", label: "America/Chihuahua" },
  { country: "Mexico", label: "America/Hermosillo" },
  { country: "Mexico", label: "America/Matamoros" },
  { country: "Mexico", label: "America/Mazatlan" },
  { country: "Mexico", label: "America/Merida" },
  { country: "Mexico", label: "America/Mexico_City" },
  { country: "Mexico", label: "America/Monterrey" },
  { country: "Mexico", label: "America/Ojinaga" },
  { country: "Mexico", label: "America/Tijuana" },
  { country: "Micronesia, Federated States Of", label: "Pacific/Chuuk" },
  { country: "Micronesia, Federated States Of", label: "Pacific/Kosrae" },
  { country: "Micronesia, Federated States Of", label: "Pacific/Pohnpei" },
  { country: "Moldova", label: "Europe/Chisinau" },
  { country: "Monaco", label: "Europe/Monaco" },
  { country: "Mongolia", label: "Asia/Choibalsan" },
  { country: "Mongolia", label: "Asia/Hovd" },
  { country: "Mongolia", label: "Asia/Ulaanbaatar" },
  { country: "Montenegro", label: "Europe/Belgrade" },
  { country: "Montenegro", label: "Europe/Podgorica" },
  { country: "Montserrat", label: "America/Montserrat" },
  { country: "Montserrat", label: "America/Port_of_Spain" },
  { country: "Morocco", label: "Africa/Casablanca" },
  { country: "Mozambique", label: "Africa/Maputo" },
  { country: "Myanmar", label: "Asia/Yangon" },
  { country: "Namibia", label: "Africa/Windhoek" },
  { country: "Nauru", label: "Pacific/Nauru" },
  { country: "Nepal", label: "Asia/Kathmandu" },
  { country: "Netherlands", label: "Europe/Amsterdam" },
  { country: "New Caledonia", label: "Pacific/Noumea" },
  { country: "New Zealand", label: "Pacific/Auckland" },
  { country: "New Zealand", label: "Pacific/Chatham" },
  { country: "Nicaragua", label: "America/Managua" },
  { country: "Niger", label: "Africa/Lagos" },
  { country: "Niger", label: "Africa/Niamey" },
  { country: "Nigeria", label: "Africa/Lagos" },
  { country: "Niue", label: "Pacific/Niue" },
  { country: "Norfolk Island", label: "Pacific/Norfolk" },
  { country: "North Korea", label: "Asia/Pyongyang" },
  { country: "Northern Mariana Islands", label: "Pacific/Guam" },
  { country: "Northern Mariana Islands", label: "Pacific/Saipan" },
  { country: "Norway", label: "Europe/Oslo" },
  { country: "Oman", label: "Asia/Dubai" },
  { country: "Oman", label: "Asia/Muscat" },
  { country: "Pakistan", label: "Asia/Karachi" },
  { country: "Palau", label: "Pacific/Palau" },
  { country: "Palestinian Territory, Occupied", label: "Asia/Gaza" },
  { country: "Palestinian Territory, Occupied", label: "Asia/Hebron" },
  { country: "Panama", label: "America/Panama" },
  { country: "Papua New Guinea", label: "Pacific/Bougainville" },
  { country: "Papua New Guinea", label: "Pacific/Port_Moresby" },
  { country: "Paraguay", label: "America/Asuncion" },
  { country: "Peru", label: "America/Lima" },
  { country: "Philippines", label: "Asia/Manila" },
  { country: "Pitcairn", label: "Pacific/Pitcairn" },
  { country: "Poland", label: "Europe/Warsaw" },
  { country: "Portugal", label: "Atlantic/Azores" },
  { country: "Portugal", label: "Atlantic/Madeira" },
  { country: "Portugal", label: "Europe/Lisbon" },
  { country: "Puerto Rico", label: "America/Puerto_Rico" },
  { country: "Qatar", label: "Asia/Qatar" },
  { country: "Reunion", label: "Indian/Reunion" },
  { country: "Romania", label: "Europe/Bucharest" },
  { country: "Russian Federation", label: "Asia/Anadyr" },
  { country: "Russian Federation", label: "Asia/Barnaul" },
  { country: "Russian Federation", label: "Asia/Chita" },
  { country: "Russian Federation", label: "Asia/Irkutsk" },
  { country: "Russian Federation", label: "Asia/Kamchatka" },
  { country: "Russian Federation", label: "Asia/Khandyga" },
  { country: "Russian Federation", label: "Asia/Krasnoyarsk" },
  { country: "Russian Federation", label: "Asia/Magadan" },
  { country: "Russian Federation", label: "Asia/Novokuznetsk" },
  { country: "Russian Federation", label: "Asia/Novosibirsk" },
  { country: "Russian Federation", label: "Asia/Omsk" },
  { country: "Russian Federation", label: "Asia/Sakhalin" },
  { country: "Russian Federation", label: "Asia/Srednekolymsk" },
  { country: "Russian Federation", label: "Asia/Tomsk" },
  { country: "Russian Federation", label: "Asia/Ust-Nera" },
  { country: "Russian Federation", label: "Asia/Vladivostok" },
  { country: "Russian Federation", label: "Asia/Yakutsk" },
  { country: "Russian Federation", label: "Asia/Yekaterinburg" },
  { country: "Russian Federation", label: "Europe/Astrakhan" },
  { country: "Russian Federation", label: "Europe/Kaliningrad" },
  { country: "Russian Federation", label: "Europe/Kirov" },
  { country: "Russian Federation", label: "Europe/Moscow" },
  { country: "Russian Federation", label: "Europe/Samara" },
  { country: "Russian Federation", label: "Europe/Saratov" },
  { country: "Russian Federation", label: "Europe/Simferopol" },
  { country: "Russian Federation", label: "Europe/Ulyanovsk" },
  { country: "Russian Federation", label: "Europe/Volgograd" },
  { country: "Rwanda", label: "Africa/Kigali" },
  { country: "Rwanda", label: "Africa/Maputo" },
  { country: "Saint Barthelemy", label: "America/Port_of_Spain" },
  { country: "Saint Barthelemy", label: "America/St_Barthelemy" },
  { country: "Saint Helena", label: "Africa/Abidjan" },
  { country: "Saint Helena", label: "Atlantic/St_Helena" },
  { country: "Saint Kitts And Nevis", label: "America/Port_of_Spain" },
  { country: "Saint Kitts And Nevis", label: "America/St_Kitts" },
  { country: "Saint Lucia", label: "America/Port_of_Spain" },
  { country: "Saint Lucia", label: "America/St_Lucia" },
  { country: "Saint Martin", label: "America/Marigot" },
  { country: "Saint Martin", label: "America/Port_of_Spain" },
  { country: "Saint Pierre And Miquelon", label: "America/Miquelon" },
  { country: "Saint Vincent And Grenadines", label: "America/Port_of_Spain" },
  { country: "Saint Vincent And Grenadines", label: "America/St_Vincent" },
  { country: "Samoa", label: "Pacific/Apia" },
  { country: "San Marino", label: "Europe/Rome" },
  { country: "San Marino", label: "Europe/San_Marino" },
  { country: "Sao Tome And Principe", label: "Africa/Sao_Tome" },
  { country: "Saudi Arabia", label: "Asia/Riyadh" },
  { country: "Senegal", label: "Africa/Abidjan" },
  { country: "Senegal", label: "Africa/Dakar" },
  { country: "Serbia", label: "Europe/Belgrade" },
  { country: "Seychelles", label: "Indian/Mahe" },
  { country: "Sierra Leone", label: "Africa/Abidjan" },
  { country: "Sierra Leone", label: "Africa/Freetown" },
  { country: "Singapore", label: "Asia/Singapore" },
  { country: "Sint Maarten", label: "America/Curacao" },
  { country: "Sint Maarten", label: "America/Lower_Princes" },
  { country: "Slovakia", label: "Europe/Bratislava" },
  { country: "Slovakia", label: "Europe/Prague" },
  { country: "Slovenia", label: "Europe/Belgrade" },
  { country: "Slovenia", label: "Europe/Ljubljana" },
  { country: "Solomon Islands", label: "Pacific/Guadalcanal" },
  { country: "Somalia", label: "Africa/Mogadishu" },
  { country: "Somalia", label: "Africa/Nairobi" },
  { country: "South Africa", label: "Africa/Johannesburg" },
  {
    country: "South Georgia And Sandwich Isl.",
    label: "Atlantic/South_Georgia",
  },
  { country: "South Sudan", label: "Africa/Juba" },
  { country: "Spain", label: "Africa/Ceuta" },
  { country: "Spain", label: "Atlantic/Canary" },
  { country: "Spain", label: "Europe/Madrid" },
  { country: "Sri Lanka", label: "Asia/Colombo" },
  { country: "Sudan", label: "Africa/Khartoum" },
  { country: "Suriname", label: "America/Paramaribo" },
  { country: "Svalbard And Jan Mayen", label: "Arctic/Longyearbyen" },
  { country: "Svalbard And Jan Mayen", label: "Europe/Oslo" },
  { country: "Swaziland", label: "Africa/Johannesburg" },
  { country: "Swaziland", label: "Africa/Mbabane" },
  { country: "Sweden", label: "Europe/Stockholm" },
  { country: "Switzerland", label: "Europe/Zurich" },
  { country: "Syrian Arab Republic", label: "Asia/Damascus" },
  { country: "Taiwan", label: "Asia/Taipei" },
  { country: "Tajikistan", label: "Asia/Dushanbe" },
  { country: "Tanzania", label: "Africa/Dar_es_Salaam" },
  { country: "Tanzania", label: "Africa/Nairobi" },
  { country: "Thailand", label: "Asia/Bangkok" },
  { country: "Timor-Leste", label: "Asia/Dili" },
  { country: "Togo", label: "Africa/Abidjan" },
  { country: "Togo", label: "Africa/Lome" },
  { country: "Tokelau", label: "Pacific/Fakaofo" },
  { country: "Tonga", label: "Pacific/Tongatapu" },
  { country: "Trinidad And Tobago", label: "America/Port_of_Spain" },
  { country: "Tunisia", label: "Africa/Tunis" },
  { country: "Turkey", label: "Europe/Istanbul" },
  { country: "Turkmenistan", label: "Asia/Ashgabat" },
  { country: "Turks And Caicos Islands", label: "America/Grand_Turk" },
  { country: "Tuvalu", label: "Pacific/Funafuti" },
  { country: "Uganda", label: "Africa/Kampala" },
  { country: "Uganda", label: "Africa/Nairobi" },
  { country: "Ukraine", label: "Europe/Kiev" },
  { country: "Ukraine", label: "Europe/Simferopol" },
  { country: "Ukraine", label: "Europe/Uzhgorod" },
  { country: "Ukraine", label: "Europe/Zaporozhye" },
  { country: "United Arab Emirates", label: "Asia/Dubai" },
  { country: "United Kingdom", label: "Europe/London" },
  { country: "United States", label: "America/Adak" },
  { country: "United States", label: "America/Anchorage" },
  { country: "United States", label: "America/Boise" },
  { country: "United States", label: "America/Chicago" },
  { country: "United States", label: "America/Denver" },
  { country: "United States", label: "America/Detroit" },
  { country: "United States", label: "America/Indiana/Indianapolis" },
  { country: "United States", label: "America/Indiana/Knox" },
  { country: "United States", label: "America/Indiana/Marengo" },
  { country: "United States", label: "America/Indiana/Petersburg" },
  { country: "United States", label: "America/Indiana/Tell_City" },
  { country: "United States", label: "America/Indiana/Vevay" },
  { country: "United States", label: "America/Indiana/Vincennes" },
  { country: "United States", label: "America/Indiana/Winamac" },
  { country: "United States", label: "America/Juneau" },
  { country: "United States", label: "America/Kentucky/Louisville" },
  { country: "United States", label: "America/Kentucky/Monticello" },
  { country: "United States", label: "America/Los_Angeles" },
  { country: "United States", label: "America/Menominee" },
  { country: "United States", label: "America/Metlakatla" },
  { country: "United States", label: "America/New_York" },
  { country: "United States", label: "America/Nome" },
  { country: "United States", label: "America/North_Dakota/Beulah" },
  { country: "United States", label: "America/North_Dakota/Center" },
  { country: "United States", label: "America/North_Dakota/New_Salem" },
  { country: "United States", label: "America/Phoenix" },
  { country: "United States", label: "America/Sitka" },
  { country: "United States", label: "America/Yakutat" },
  { country: "United States", label: "Pacific/Honolulu" },
  { country: "United States Outlying Islands", label: "Pacific/Honolulu" },
  { country: "United States Outlying Islands", label: "Pacific/Midway" },
  { country: "United States Outlying Islands", label: "Pacific/Pago_Pago" },
  { country: "United States Outlying Islands", label: "Pacific/Wake" },
  { country: "Uruguay", label: "America/Montevideo" },
  { country: "Uzbekistan", label: "Asia/Samarkand" },
  { country: "Uzbekistan", label: "Asia/Tashkent" },
  { country: "Vanuatu", label: "Pacific/Efate" },
  { country: "Venezuela", label: "America/Caracas" },
  { country: "Vietnam", label: "Asia/Bangkok" },
  { country: "Vietnam", label: "Asia/Ho_Chi_Minh" },
  { country: "Virgin Islands, British", label: "America/Port_of_Spain" },
  { country: "Virgin Islands, British", label: "America/Tortola" },
  { country: "Virgin Islands, U.S.", label: "America/Port_of_Spain" },
  { country: "Virgin Islands, U.S.", label: "America/St_Thomas" },
  { country: "Wallis And Futuna", label: "Pacific/Wallis" },
  { country: "Western Sahara", label: "Africa/El_Aaiun" },
  { country: "Yemen", label: "Asia/Aden" },
  { country: "Yemen", label: "Asia/Riyadh" },
  { country: "Zambia", label: "Africa/Lusaka" },
  { country: "Zambia", label: "Africa/Maputo" },
  { country: "Zimbabwe", label: "Africa/Harare" },
  { country: "Zimbabwe", label: "Africa/Maputo" },
];

export default timezones;
