import React from "react";
import { useNavigate } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import MenuIcon from "@mui/icons-material/Menu";
import { useTransient } from "./ProviderTransient";
import Typography from "@mui/material/Typography";

const Header = () => {
  const [{}, dispatchTransient] = useTransient();
  const showMenu = () => {
    dispatchTransient(["setMenuShown", { menuShown: true }]);
  };

  const navigate = useNavigate();
  const handleHomeClick = (event) => {
    event.preventDefault();
    navigate("/");
  };

  return (
    <AppBar position="relative">
      <Toolbar>
        <Box sx={{ flexGrow: 1 }}>
          <Link
            variant="h5"
            color="inherit"
            href="/"
            onClick={handleHomeClick}
            underline="none"
            noWrap
          >
            <Typography variant="span" pr={2}>
              ∆
            </Typography>
            How Many Seconds
          </Link>
        </Box>

        <Tooltip title="Menu" placement="bottom-end">
          <IconButton onClick={showMenu} color="inherit">
            <MenuIcon />
          </IconButton>
        </Tooltip>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
