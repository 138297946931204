import { useEffect, useMemo } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { getSortedUnique, getCountriesTimezones } from "./utils";
import timezones from "./timezones";
import { useTransient } from "./ProviderTransient";

const Timezone = () => {
  const [{ entry, timezoneSelection }, dispatchTransient] = useTransient();

  useEffect(() => {
    dispatchTransient(["initTimezoneSelection", { timezone: entry.timezone }]);
  }, []);

  const hideDialog = () => {
    dispatchTransient(["updateEntry", { showDialog: false }]);
  };

  const handleCountriesChange = (event, countries) => {
    let timezone = null;
    let timezoneError = "Timezone is required";
    const filteredTimezones = getCountriesTimezones(timezones, countries);
    if (filteredTimezones.length === 1) {
      timezone = filteredTimezones[0];
      timezoneError = "";
    } else if (
      timezoneSelection.timezone &&
      filteredTimezones.includes(timezoneSelection.timezone)
    ) {
      timezone = timezoneSelection.timezone;
      timezoneError = "";
    }
    dispatchTransient([
      "updateTimezoneSelection",
      { countries, timezone, timezoneError },
    ]);
  };
  const handleTimezoneChange = (event, timezone) => {
    let timezoneError = "";
    if (!timezone) {
      timezoneError = "Timezone is required";
    }
    dispatchTransient(["updateTimezoneSelection", { timezone, timezoneError }]);
  };
  const handleSelectClick = () => {
    if (timezoneSelection.timezoneError) {
      dispatchTransient(["updateTimezoneSelection", { showErrors: true }]);
    } else {
      hideDialog();
      dispatchTransient([
        "updateEntry",
        { timezone: timezoneSelection.timezone },
      ]);
    }
  };

  return (
    <>
      <Dialog open={true} onClose={hideDialog} maxWidth="xs">
        <DialogTitle>Timezone Selection</DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            <Typography>
              Choose one or more countries to filter the list of timezones.
            </Typography>
            <Autocomplete
              multiple
              value={timezoneSelection.countries}
              options={getSortedUnique(
                timezones.map((timezone) => timezone.country)
              )}
              onChange={handleCountriesChange}
              renderInput={(props) => (
                <TextField {...props} label="Countries" />
              )}
            />
            <Autocomplete
              value={timezoneSelection.timezone}
              options={getCountriesTimezones(
                timezones,
                timezoneSelection.countries
              )}
              onChange={handleTimezoneChange}
              renderInput={(props) => (
                <TextField
                  {...props}
                  label="Timezone"
                  error={
                    timezoneSelection.showErrors &&
                    timezoneSelection.timezoneError !== ""
                  }
                  helperText={
                    timezoneSelection.showErrors &&
                    timezoneSelection.timezoneError
                  }
                />
              )}
            />
            <Typography>
              Still not sure which one to choose? Check out{" "}
              <Link component="a" href="https://askgeo.com" target="_blank">
                Ask Geo
              </Link>
              . Select the location on the map then use the TimeZoneId value.
            </Typography>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button variant="text" color="primary" onClick={hideDialog}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSelectClick}
          >
            Select
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Timezone;
