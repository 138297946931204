import React from "react";
import { useNavigate } from "react-router-dom";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import AddIcon from "@mui/icons-material/Add";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import HelpIcon from "@mui/icons-material/Help";
import AlarmIcon from "@mui/icons-material/Alarm";
import { AppBar, IconButton, Toolbar, Tooltip } from "@mui/material";
import { useTransient } from "./ProviderTransient";
import { usePersistent } from "./ProviderPersistent";

const Menu = () => {
  const [{ timers }] = usePersistent();

  const [{ menuShown }, dispatchTransient] = useTransient();
  const hideMenu = () => {
    dispatchTransient(["setMenuShown", { menuShown: false }]);
  };

  const navigate = useNavigate();
  const handleTimersClick = () => {
    hideMenu();
    navigate("/");
  };
  const handleNewClick = () => {
    hideMenu();
    navigate("/entry");
  };
  const handleHelpClick = () => {
    hideMenu();
    navigate("/help");
  };

  const timersButton = () => {
    if (timers.length) {
      return (
        <ListItem disablePadding>
          <ListItemButton onClick={handleTimersClick} color="inherit">
            <ListItemIcon>
              <AlarmIcon />
            </ListItemIcon>
            <ListItemText primary="Timers" />
          </ListItemButton>
        </ListItem>
      );
    }
    return null;
  };

  return (
    <Drawer anchor="right" open={menuShown} onClose={hideMenu}>
      <AppBar position="relative">
        <Toolbar>
          <Tooltip title="Close" placement="bottom-end">
            <IconButton onClick={hideMenu} color="inherit">
              <ChevronRightIcon />
            </IconButton>
          </Tooltip>
        </Toolbar>
      </AppBar>
      <List>
        {timersButton()}
        <ListItem disablePadding>
          <ListItemButton onClick={handleNewClick} color="inherit">
            <ListItemIcon>
              <AddIcon />
            </ListItemIcon>
            <ListItemText primary="New Timer" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton onClick={handleHelpClick} color="inherit">
            <ListItemIcon>
              <HelpIcon />
            </ListItemIcon>
            <ListItemText primary="Help" />
          </ListItemButton>
        </ListItem>
      </List>
    </Drawer>
  );
};

export default Menu;
