import React, { useState, useMemo } from "react";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Card from "@mui/material/Card";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { useTransient } from "./ProviderTransient.js";
import {
  calculateMilestones,
  displayDateTime,
  recalculateMilestones,
} from "./utils.js";

const Milestones = () => {
  const [{ milestoneDate, now }, dispatchTransient] = useTransient();

  const date = milestoneDate;

  const [milestonesNow, setMilestonesNow] = useState(now);
  const milestonesNowString = milestonesNow.format();
  const dateString = date ? date.format() : "";

  const milestones = useMemo(() => {
    return calculateMilestones(milestonesNow, date);
  }, [milestonesNowString, dateString]);
  if (recalculateMilestones(now, milestones, date)) {
    setMilestonesNow(now);
  }

  const onClose = () =>
    dispatchTransient(["setMilestoneDate", { milestoneDate: null }]);

  const mapMilestones = () => {
    return milestones.map(({ type, occurances }) => {
      return (
        <Card
          key={type}
          raised={true}
          sx={{
            p: 1,
            backgroundColor: "lightgrey",
          }}
        >
          <Typography variant="body1">{type}</Typography>
          <Table size="small" padding="checkbox">
            <TableBody>
              {occurances.map(({ date, duration }) => {
                const cellSx = {
                  textAlign: "right",
                };
                if (!date.isAfter(now)) {
                  cellSx.textDecoration = "line-through";
                }
                return (
                  <TableRow
                    key={duration}
                    sx={{
                      whiteSpace: "nowrap",
                      "&:last-child td": { border: 0 },
                      "& td:first-of-type": { textAlign: "right" },
                    }}
                  >
                    <TableCell sx={cellSx}>
                      {duration.toLocaleString()}
                    </TableCell>
                    <TableCell sx={cellSx}>{displayDateTime(date)}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Card>
      );
    });
  };
  return (
    <Dialog open={true} onClose={onClose}>
      <DialogTitle>Milestones</DialogTitle>
      <DialogContent>
        <Stack spacing={2}>{milestones && mapMilestones()}</Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Milestones;
