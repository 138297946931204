import React from "react";
import Header from "./Header";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import { Outlet } from "react-router-dom";
import StyledEngineProvider from "@mui/material/StyledEngineProvider";
import Menu from "./Menu";
import Footer from "./Footer";
import ProviderTransient from "./ProviderTransient";
import ProviderPersistent from "./ProviderPersistent";

const App = () => {
  return (
    <StyledEngineProvider injectFirst>
      <CssBaseline />
      <ProviderTransient>
        <ProviderPersistent>
          <Header />
          <Container maxWidth="xl" sx={{ pt: 2 }}>
            <Outlet />
          </Container>
          <Footer />
          <Menu />
        </ProviderPersistent>
      </ProviderTransient>
    </StyledEngineProvider>
  );
};

export default App;
