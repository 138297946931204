import moment from "moment-timezone";
import React, { createContext, useContext, useEffect, useReducer } from "react";
import timezones from "./timezones";
import { getTimezoneCountries } from "./utils";

const initial = {
  entry: {
    showErrors: false,
    deleteTimeout: null,
    title: "",
    titleError: "Title is required",
    date: moment(),
    dateError: "",
    time: moment(),
    timeError: "",
    timezone: moment.tz.guess(true),
    showDialog: false,
  },
  menuShown: false,
  milestoneDate: null,
  now: moment().milliseconds(0),
  timezoneSelection: {
    showErrors: false,
    countries: getTimezoneCountries(timezones, moment.tz.guess(true)),
    timezone: moment.tz.guess(true),
    timezoneError: "",
  },
};
const reducer = (state, [type, payload]) => {
  const newState = { ...state };
  switch (type) {
    case "initEntry":
      if (payload?.timer) {
        const dateTime = moment
          .utc(payload.timer.dateTime)
          .tz(payload.timer.timezone);
        newState.entry = {
          ...initial.entry,
          title: payload.timer.title,
          titleError: "",
          date: dateTime,
          time: dateTime,
          timezone: payload.timer.timezone,
        };
      } else {
        newState.entry = { ...initial.entry, date: moment(), time: moment() };
      }
      break;
    case "initTimezoneSelection":
      newState.timezoneSelection = {
        ...initial.timezoneSelection,
        countries: getTimezoneCountries(timezones, payload.timezone),
        timezone: payload.timezone,
      };
      break;
    case "setMenuShown":
      newState.menuShown = payload.menuShown;
      break;
    case "setMilestoneDate":
      newState.milestoneDate = payload.milestoneDate;
      break;
    case "setNow":
      newState.now = moment().milliseconds(0);
      break;
    case "updateEntry":
      newState.entry = { ...state.entry, ...payload };
      break;
    case "updateTimezoneSelection":
      newState.timezoneSelection = { ...state.timezoneSelection, ...payload };
      break;
    default:
      break;
  }
  return newState;
};

const Context = createContext();
export const useTransient = () => useContext(Context);

export default function ProviderTransient({ children }) {
  const [transient, dispatchTransient] = useReducer(reducer, initial);

  useEffect(() => {
    const id = setInterval(() => dispatchTransient(["setNow"]), 1000);
    return () => clearInterval(id);
  }, []);

  return (
    <Context.Provider value={[transient, dispatchTransient]}>
      {children}
    </Context.Provider>
  );
}
