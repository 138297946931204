import React from "react";
import Timers from "./Timers";
import { useParams } from "react-router-dom";

const Custom = () => {
  const params = useParams();
  const timers = [{ dateTime: params.dateTime, title: params.title }];

  return <Timers timers={timers} />;
};

export default Custom;
