import React, { useEffect } from "react";
import Timers from "./Timers";
import { useNavigate } from "react-router-dom";
import { usePersistent } from "./ProviderPersistent";

const Home = () => {
  const [{ timers }] = usePersistent();
  const navigate = useNavigate();
  useEffect(() => {
    if (!timers.length) {
      navigate("/help");
    }
  }, [navigate]);

  return <Timers timers={timers} />;
};

export default Home;
