import React, { createContext, useContext, useReducer } from "react";

const version = 1;
const item = "state";
const ls = JSON.parse(localStorage.getItem(item));
const initial =
  ls !== null && ls.version === version
    ? ls
    : {
        decimals: [0, 3, 3, 3, 3, 3, 3],
        timers: [],
        version,
      };
const reducer = (state, [type, payload]) => {
  const newState = { ...state };
  switch (type) {
    case "addTimer":
      const generateId = (timers) => {
        const id =
          Date.now().toString(36) + Math.random().toString(36).substring(2);
        return timers.find((timer) => timer.id === id) ? generateId() : id;
      };
      payload.timer.id = generateId(state.timers);
      newState.timers = [...state.timers, payload.timer];
      break;
    case "updateTimer":
      newState.timers = state.timers.map((timer) =>
        timer.id === payload.timer.id ? payload.timer : timer
      );
      break;
    case "deleteTimer":
      newState.timers = state.timers.filter(
        (timer) => timer.id !== payload.timerId
      );
      break;
    default:
      break;
  }
  localStorage.setItem(item, JSON.stringify(newState));
  return newState;
};

const Context = createContext();
export const usePersistent = () => useContext(Context);

export default function ProviderPersistent({ children }) {
  const [persistent, dispatchPersistent] = useReducer(reducer, initial);

  return (
    <Context.Provider value={[persistent, dispatchPersistent]}>
      {children}
    </Context.Provider>
  );
}
