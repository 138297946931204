import React, { useState } from "react";
import moment from "moment-timezone";
import Typography from "@mui/material/Typography";
import {
  copyToClipboard,
  calculateMilestones,
  calculateNextMilestone,
  displayDateTime,
} from "./utils.js";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Link from "@mui/material/Link";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import LinkIcon from "@mui/icons-material/Link";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import { useNavigate } from "react-router-dom";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import { useTransient } from "./ProviderTransient.js";
import { usePersistent } from "./ProviderPersistent.js";
import { Alert, Snackbar } from "@mui/material";

const Timer = ({ timer }) => {
  const [{ now }, dispatchTransient] = useTransient();
  const [{}, dispatchPersistent] = usePersistent();

  const [copiedShown, setCopiedShown] = useState(false);

  const navigate = useNavigate();

  const dateTime = moment.utc(timer.dateTime);
  if (!dateTime.isValid()) {
    return "Invalid Date";
  }

  const pathname =
    "/custom/" + timer.dateTime + "/" + encodeURIComponent(timer.title);

  const handleCopy = () => {
    const url = window.location.origin + pathname;
    if (copyToClipboard(url)) {
      setCopiedShown(true);
    }
  };
  const handleLink = (event) => {
    event.preventDefault();
    navigate(pathname);
  };
  const handleEdit = () => {
    navigate("/entry/" + timer.id);
  };
  const handleSave = () => {
    dispatchPersistent([
      "addTimer",
      {
        timer: {
          dateTime: timer.dateTime,
          title: timer.title,
          timezone: moment.tz.guess(true),
        },
      },
    ]);
    navigate("/");
  };

  const milestones = calculateMilestones(now, dateTime);
  const nextMilestone = calculateNextMilestone(now, milestones);

  const display = (type) => {
    const diff =
      Math.trunc(Math.abs(Math.floor(now.diff(dateTime, type, true) * 1000))) /
      1000;
    const decimals = type === "Seconds" ? 0 : 3;
    return (
      <Grid container spacing={1}>
        <Grid item xs={6} sx={{ textAlign: "right" }}>
          {diff.toLocaleString(undefined, {
            minimumFractionDigits: decimals,
            maximumFractionDigits: decimals,
          })}
        </Grid>
        <Grid item xs={6} sx={{ textAlign: "left" }}>
          {type.replace(/s$/, parseFloat(diff) === 1 ? "" : "s")}
        </Grid>
      </Grid>
    );
  };
  return (
    <Card
      id={timer.id}
      raised={true}
      sx={{
        bgcolor: "lightgrey",
        p: 1,
        textAlign: "center",
        width: 400,
        position: "relative",
      }}
    >
      <Snackbar
        open={copiedShown}
        autoHideDuration={6000}
        onClose={() => setCopiedShown(false)}
      >
        <Alert onClose={() => setCopiedShown(false)} severity="success">
          Timer link copied! Use this{" "}
          <Link onClick={handleLink} href={pathname}>
            link
          </Link>{" "}
          to share the <b>{timer.title}</b> timer.
        </Alert>
      </Snackbar>
      <Stack direction="row" spacing={1}>
        <Tooltip title="Copy Timer Link" placement="bottom-start">
          <IconButton onClick={handleCopy} color="inherit">
            <LinkIcon />
          </IconButton>
        </Tooltip>
        <Typography variant="h5" flexGrow="1" noWrap sx={{ py: 0.5 }}>
          {timer.title}
        </Typography>
        {timer.id && (
          <Tooltip title="Edit" placement="bottom-end">
            <IconButton onClick={handleEdit} color="inherit">
              <EditIcon />
            </IconButton>
          </Tooltip>
        )}
        {!timer.id && (
          <Tooltip title="Save" placement="bottom-end">
            <IconButton onClick={handleSave} color="inherit">
              <SaveAltIcon />
            </IconButton>
          </Tooltip>
        )}
      </Stack>
      <Typography variant="body2">
        {displayDateTime(dateTime, "LLLL")}
      </Typography>
      {display("Seconds")}
      {display("Minutes")}
      {display("Hours")}
      {display("Days")}
      {display("Weeks")}
      {display("Months")}
      {display("Years")}
      <br />
      Next{" "}
      <Link
        component="button"
        variant="body1"
        onClick={() => {
          dispatchTransient(["setMilestoneDate", { milestoneDate: dateTime }]);
        }}
      >
        Milestone
      </Link>
      :<br />
      {nextMilestone &&
        `${nextMilestone.duration.toLocaleString()} ${
          nextMilestone.type
        }: ${displayDateTime(nextMilestone.date)}`}{" "}
    </Card>
  );
};

export default Timer;
