import moment from "moment-timezone";

const getSortedUnique = (array) => {
  return [...new Set(array)].sort();
};

const getTimezoneCountries = (timezones, timezone) => {
  return timezones
    .filter(({ label }) => label === timezone)
    .map((timezone) => timezone.country);
};
const getCountriesTimezones = (timezones, countries) => {
  return getSortedUnique(
    timezones
      .filter(
        (timezone) =>
          countries.length === 0 || countries.includes(timezone.country)
      )
      .map((timezone) => timezone.label)
  );
};

const copyToClipboard = (text) => {
  if (navigator.clipboard) {
    navigator.clipboard.writeText(text);
    return true;
  }
  alert("Browser does not support Clipboard updates");
  return false;
};

const calculateMilestones = (now, date) => {
  var milestones = [
    { type: "Seconds", occurances: [] },
    { type: "Minutes", occurances: [] },
    { type: "Hours", occurances: [] },
    { type: "Days", occurances: [] },
    { type: "Weeks", occurances: [] },
    { type: "Months", occurances: [] },
    { type: "Years", occurances: [] },
  ];

  const futureDate = date.isAfter(now);

  milestones.forEach(({ type, occurances }) => {
    const diff = Math.abs(
      now.diff(date, type) + (futureDate ? 1 : 0)
    ).toString();
    const delta = 10 ** (diff.length - 1);

    for (let i = 1; i <= 10; i++) {
      const duration = delta * i;
      const occuranceDate = date.clone();
      futureDate
        ? occuranceDate.subtract(duration, type)
        : occuranceDate.add(duration, type);
      occurances.push({ duration, date: occuranceDate });
    }

    futureDate && occurances.reverse();
  });

  return milestones;
};

const calculateNextMilestone = (now, milestones) => {
  let nextMilestone;
  milestones.forEach(({ type, occurances }) => {
    occurances.forEach(({ date, duration }) => {
      if (
        date.isAfter(now) &&
        (!nextMilestone || date.isBefore(nextMilestone.date))
      ) {
        nextMilestone = { date, duration, type };
      }
    });
  });
  return nextMilestone;
};

const displayDateTime = (dateTime, format = "lll") => {
  const currentTimezone = moment.tz.guess();
  const dateTimeTimezone = dateTime.clone().tz(currentTimezone);
  return dateTimeTimezone.format(format);
};

const recalculateMilestones = (now, milestones, date) => {
  let recalculate = false;
  milestones.forEach(({ occurances }) => {
    const occurance = occurances[occurances.length - 1];
    if (!now.isBefore(occurance.date) && occurance.duration !== 1) {
      recalculate = true;
    }
    if (occurance.date.isBefore(now) && !now.isBefore(date)) {
      recalculate = true;
    }
  });
  return recalculate;
};

const recalculateNextMilestone = (now, nextMilestone) => {
  return !now.isBefore(nextMilestone.date);
};

export {
  getSortedUnique,
  getTimezoneCountries,
  getCountriesTimezones,
  copyToClipboard,
  calculateMilestones,
  calculateNextMilestone,
  displayDateTime,
  recalculateMilestones,
  recalculateNextMilestone,
};
